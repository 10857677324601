<template>
  <v-dialog
    width="400"
    :value="show"
    @click:outside="cancel"
    @keydown="handleKeydown"
  >
    <v-card>
      <v-form
        class="text-center"
        ref="form"
        v-model="form.valid"
        @submit.prevent="handleSubmit"
      >
        <v-card-title>{{ title }}</v-card-title>

        <v-card-text>
          <v-row>
            <v-col cols="12">
              <v-text-field
                clearable
                required
                clear-icon="$delete"
                autocomplete="off"
                class="validation"
                :counter="100"
                label="Name"
                v-model="name"
                :rules="form.rules.name"
              ></v-text-field>
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="12">
              <group-member-input
                label="Mitglieder hinzufügen"
                :existing-members="members"
                @member-selected="handleMemberSelected"
              ></group-member-input>
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="12">
              <group-member-list
                class="text-left"
                v-model="members"
              ></group-member-list>
            </v-col>
          </v-row>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>

          <temeno-circle-button action title="abbrechen" @click="cancel">
            $close
          </temeno-circle-button>

          <temeno-circle-button
            action
            title="speichern"
            type="submit"
            :disabled="!form.valid"
          >
            {{ !form.valid ? '$approve_action_disabled' : '$approve' }}
          </temeno-circle-button>
        </v-card-actions>
      </v-form>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: "GroupEditor",
  components: {
    GroupMemberInput: () => import("@/components/GroupMemberInput"),
    GroupMemberList: () => import("@/components/GroupMemberList"),
    TemenoCircleButton: () => import("@/components/TemenoCircleButton"),
  },
  props: {
    show: Boolean,
    value: Object,
  },
  data() {
    return {
      form: {
        rules: {
          name: [(v) => (v && v.length > 0) || this.$t("validation.mandatory")],
        },
        valid: false,
      },
      id: null,
      members: [],
      name: null,
    };
  },
  computed: {
    title() {
      return this.id != null ? "Gruppe bearbeiten" : "Neue Gruppe erstellen";
    },
  },
  watch: {
    value() {
      if (this.value) {
        const { id, members, name } = this.value;
        this.id = id;
        this.members = members;
        this.name = name;
      } else {
        this.id = null;
        this.members = [];
        this.name = null;
      }
    },
  },
  methods: {
    cancel() {
      this.$emit("cancel");
      this.reset();
    },
    handleKeydown(key) {
      if (key.code === "Escape") {
        this.cancel();
      }
    },
    handleMemberSelected(member) {
      this.members.push(member);
    },
    handleSubmit() {
      const { id, members, name } = this;
      this.$emit("input", { id, members, name });
    },
    reset() {
      this.id = null;
      this.members = [];
      this.name = null;
      this.$refs.form.reset();
    },
  },
  i18n: {
    messages: {
      de: {
        validation: {
          mandatory: "Dies ist ein Pflichtfeld",
        },
      },
    },
  },
};
</script>
